@use "sass:math";

@import "./../global-variables";
@import "./../shared-components";


.pre-test-container{
    max-width:1000px;
    margin-left:auto;
    margin-right:auto;
}

@media screen and (max-width: $mobile-breakpoint) {
    .pre-test-container{
        padding: 30px 40px;
    }
}


.button-container{
    margin-top:30px;
    display:flex;
    justify-content:center;
}




.time-text{
    margin-left:30px;
}



.time-subtitle{
    text-align:center;
}


.time-boxes{
    padding-top:50px;
    display:flex;
    max-width:3000px;
    margin-left:auto;
    margin-right:auto;

    justify-content:center;

    gap:60px;

}

.box{

    width:300px;

    background-color: $textbox-color;
    padding:40px;

    text-align:center;

}

.box-top{
    height:370px;
}

.box-bottom{
    height:50px;
    display:flex;
    justify-content:center;
 
}

.box-title{
    font-size:36px;
    font-weight:500;
}

.box-subtitle{
    padding-top:40px;
    font-size: 16px;

}


.text-box{
    margin-top:60px;

    max-width:1500px;
    background-color: $textbox-color;
    padding:60px;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
}


@media screen and (max-width: $mobile-breakpoint) {
    .text-box{
        padding:30px;
    }
}
