@import "./../global-variables";
@import "./../shared-components";


$profile-max-width: 1300px;

.profile{
    max-width:$profile-max-width;
    margin-left:auto;
    margin-right:auto;
}

.profile-body{
    padding-top:12vh;
    display:flex;
    padding-left:40px;
    padding-right:40px;
}

.profile-left{
    width:45%;
}


.profile-left-container{
    margin-top:100px;
    text-align:center;

}

.profile-right{
    width:55%;
}

.profile-right-container{
    display:flex;
    flex-direction:column;
    gap: 22px;
}

.input-container{
    width:45%;
}


.settings-button{
    background-color: #272B4A80;
    padding: 10px 15px;
    text-align:center;
    max-width:400px;
}

.settings-button:hover{
    cursor:pointer;
    opacity:0.8;
}


@media screen and (max-width: $mobile-breakpoint) {

    .input-container{
        width:100%;
        
    }

    .login-button-container{
        margin-right:auto;
        margin-left:auto;
    }

}


@media screen and (max-width: $mobile-breakpoint) {
    .profile-body{
        display:flex;
        flex-direction:column;
        padding-top:35px;
        padding-left:40px;
        padding-right:40px;
    }

    .profile-left{
        width:max-content;
        margin-left:auto;
        margin-right:auto;
        height:initial;
    }

    .profile-left-container{
        margin-top:0px;
    }

    .profile-right{
        width:auto;
        height:initial;
    }

    .profile-right-container{

    }

    .title.login{
        display:none;
    }

    .subheading.login{
        display:none;
    }
}
