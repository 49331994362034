@import "./global-variables";
@import "./shared-components";


.nav{
    color:$white; 
    display:flex;
    background-color: $black;
    justify-content: space-between;

    height:$nav-height;

    font-weight:600;

}

.nav-left{
    padding-left:60px;
    margin-top:auto;
    margin-bottom:auto;

    display:flex;
    flex-direction:row;
}

.nav-homepage:hover{
    color:#4A52AD;
    transition:0.3s;
    cursor:pointer;
}

.nav-homepage.active{
    color:#4A52AD;
}

.nav-links{
    margin-left:100px;
    margin-top:auto;
    margin-bottom:auto;
}

@media screen and (max-width: $mobile-breakpoint) {
    .nav-links{
        margin-left:40px;
    }
}

.nav-links:hover{
    color:#4A52AD;
    cursor:pointer;
    transition:0.3s;
}

.nav-links.active{
    color:#4A52AD;
}

.nav-right{
    padding-right:50px;
    margin-top:auto;
    margin-bottom:auto;
}





.nav-text{
    text-align:center;
    padding-left:20px;
    font-weight:500;
    margin-top:auto;
    margin-bottom:auto;
    font-size:$nav-font-size;
}

.nav-icon{
    width:25px;
    height:auto;
}

.nav-user{
    display:flex;
    gap:20px;
}

.nav-user:hover{
    opacity:0.8;
}

.nav-user-icon{
    margin-top:auto;
    margin-bottom:auto;
    width:32px;
    height:32px;
}

.nav-user-text{
    margin-top:auto;
    margin-bottom:auto;
}

@media screen and (max-width: $mobile-breakpoint) {
    .nav{
        height:$mobile-nav-height;
    }

    .nav-left{
        padding-left:30px;
        margin-top:auto;
        margin-bottom:auto;

      
    }

    .nav-right{
        padding-right:15px;
    }

    .nav-text{
        font-size:14px;
        padding-left:0px;
    }

    .nav-icon {
        width:70%;
        height:auto;
    }

    .nav-user-text{
        display:none;
    }
}
