//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

$background-color: #1A273B;
$alt-background-color: #293343;

$black: #262626;
$white: #FFFFFF;
$button-color: #505772;
$secondary-button-color: #272B4A;
$button-hover-color: #49A372;
$secondary-button-hover-color: #49A372;

$error-text-color: #BC3D3D;

$textbox-color: #505772;

$subheading-color: #B7B7B7;

$font-size: 1.1rem;
$mobile-font-size: 0.8rem;
$subheading-font-size: 1.2rem;
$mobile-subheading-font-size: 1.2rem;
$title-font-size: 2.5rem;
$mobile-title-font-size: 1.9rem;
$secondary-heading-font-size: 1.8rem;
$mobile-secondary-font-size: 1.6rem;
$button-font-size: 1rem;
$mobile-button-font-size: 1.1rem;
$nav-font-size: 1rem;
$footer-font-size: 0.85rem;
$label-font-size: 0.95rem;

$max-width:1000px;
$footer-height: 60px;
$nav-height: 50px;
$mobile-nav-height: 45px;


$mobile-breakpoint: 1000px;




a:link{
    text-decoration: none;
    color:$white;
}

a:visited{
    text-decoration: none;
    color: $white;
}

html{
    background-color:$background-color;
    color:$white;
    font-size:$font-size;
    font-family: 'Open Sans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

@media screen and (max-width: $mobile-breakpoint) {
    html{
        font-size:$mobile-font-size;
    }
}



.app-body{
    min-height: calc(100vh - 2*($footer-height + $nav-height));
    
    background-image: linear-gradient(to top, rgba(36,38,68,0.96), rgba(30,39,62,1)), 
    url('/images/background2.png');
  
    background-repeat: no-repeat, repeat; 
    background-size: 100% 100%, 1200px 500px;

 
    padding-bottom:90px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

@media screen and (max-width: $mobile-breakpoint) {
    .app-body{
        background-size: 100% 100%, 900px 375px;
        padding-bottom:40px;
    }
}





.heading{
    font-size:$title-font-size;
    text-align:center;
    margin-top:60px
  }

  @media screen and (max-width: $mobile-breakpoint) {
    .heading{
        font-size:$mobile-title-font-size;
    }
}



.footer{
    background-color:$black;
    padding: 40px 0px;
    text-align:center;
    font-size: $footer-font-size;
}


.footer-line-container{

    display:flex;
    justify-content:center;

    gap:20px;
}

.footer-line{
    padding-top:8px;
    display:flex;
    justify-content:center;
    gap:10px;
}

.footer-link{
    padding-top:8px;
    display:flex;
    justify-content:center;
    gap:10px;
}

.footer-link:hover{
    cursor:pointer;
    color: #5861C9;
}

@media screen and (max-width: $mobile-breakpoint) {
    .footer{
        padding:40px 0px;
        font-size:10px;
        display:flex;
        flex-direction:column;
    }

    .footer-line-container{
        flex-direction: column;   
        gap:5px;    
    }
    .footer-line{
        margin-top:5px;
    }
    
}

.footer-text{
}



.heading-section{
    
    padding-top: 40px;

    max-width:600px;
    margin-left:auto;
    margin-right:auto;



}

.bigfive-logo{
    width:100px;
    height:auto;
}

@media screen and (max-width: $mobile-breakpoint) {
    .heading-section{
        padding-top:0px;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .bigfive-logo{
        width:55px;
        height:auto;
        margin-left:auto;
        margin-right:auto;

    }
}



.heading-section-subtitle{
    margin-left:80px;
    padding-top:5px;
}

.heading-section-title{
    font-size:$secondary-heading-font-size;
    text-align:left;
    margin-left:80px;
    margin-top:25px;
    flex-grow:1;
}

@media screen and (max-width: $mobile-breakpoint) {
    .heading-section-title{
        font-size:$mobile-secondary-font-size;
        margin-top:20px;
        margin-left:0px;
        text-align:center;
    }

    .heading-section-subtitle{
        margin-left: 0px;
  
        text-align:center;
    }
}

