@import "./global-variables";



.small-vertical-spacer {
    padding-top: 20px;
}


.vertical-spacer {
    padding-top: 40px;
}

.normal-flex-divider {
    display: flex;
}

.flex-divider {
    display: flex;
}

@media screen and (max-width: $mobile-breakpoint) {
    .flex-divider {
        flex-direction: column;
        align-items: center;
    }
}

.flex-center{
    display:flex;
    justify-content:center;
}


.buttons {
    padding: 40px 0px;
}

.button {
    border-radius: 25px;
    color: $white;
    padding: 8px 36px;
    background-color: $button-color;
    font-size: $button-font-size;
    font-weight: 100;
    border: none;
    display: flex;
    align-items: center;
    transition: 0.3s;
}


.button:hover {
    background-color: $button-hover-color;
    cursor: pointer;
}

@media screen and (max-width: $mobile-breakpoint) {
    .button {
        font-size: $mobile-button-font-size;
        padding: 7px 20px;
        height: 35px;
        font-weight: 500;
    }
}



@media screen and (max-width: $mobile-breakpoint) {
    .button-icon {
        width: 17px;
        height: 17px;
    }
}


.button-text {
    margin-right: 5px;
    margin-top: 1px;
}


.button.secondary {
    background-color: $secondary-button-color;
}


.button.secondary:hover {
    background-color: $secondary-button-hover-color;
    cursor: pointer;
}

.button.special {
    background-image: linear-gradient(to right, #E8E8E8, #BFBFBF);
    background-color: $white;
    color: $black;
    font-weight: 600;
    height: 18px;
}

.button.special:hover {
    cursor: pointer;
    opacity: 0.7;
}


.modal {
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #28243375;
}

.modal-content {
    background-color: #2C2C30;
    margin: 15% auto;
    padding: 40px;
    width: 30%;
    text-align: center;
}

@media screen and (max-width: $mobile-breakpoint) {
    .modal-content {
        margin: 50% auto;
        width: 50%;
    }
}

.modal-body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.modal-title {
    font-size: $secondary-heading-font-size;
    font-weight: 600;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover {
    cursor: pointer;
    opacity: 0.8;
}




.text-link {
    transition: 0.3s;
}

.text-link:hover {
    cursor: pointer;
    color: #5364FF
}

@media screen and (max-width: $mobile-breakpoint) {

    .text-link {
        text-align: center;
    }

}



.input-label {
    font-size: $label-font-size;
    margin-bottom: 5px;
}

.text-input-box {
    width: 100%;
    background-color: #40465D;
    padding: 12px 16px;
    border: none;
    color: white;
    border-radius: 25px;
    font-size: 1rem;

    box-shadow: -1px 7px 8px -8px rgba(0, 0, 0, 0.79);
    -webkit-box-shadow: -1px 7px 8px -8px rgba(0, 0, 0, 0.79);
    -moz-box-shadow: -1px 7px 8px -8px rgba(0, 0, 0, 0.79);
}

.text-input-box:focus {
    outline: none;
}


@media screen and (max-width: $mobile-breakpoint) {
    .text-input {}

    .text-input-box {
        box-sizing: border-box;
    }

}

.error-text {
    color: $error-text-color;
}