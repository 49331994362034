@import "./../global-variables";
@import "./../shared-components";





.result-chart{
   


}

.result-chart-title{
    font-size:1.5rem;
    text-align:center;
    margin-bottom:15px;
}

.result-chart-middle{
    display:flex;
    flex-direction: row;
}

.result-chart-center{
    position:relative;
    z-index:1;
    flex-grow:2;

    display:flex;
    flex-direction:column;

}

.result-chart-side{
    max-width:80px;
    //border:2px solid pink;
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
    padding:20px;
}

@media screen and (max-width: $mobile-breakpoint) {
    .result-chart-side{
        padding:0px;
    }
}


.result-chart-side-logo{
    width:60%;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:10px;
}

.result-chart-right{
    flex-basis:20%;
}

.result-chart-curve{
}

.result-chart-selector-container{
    position:absolute;
    left:0;
    top:0;
    right:0;
    z-index:2;
}

.result-chart-selector{
    //border:2px solid green;
    
}

.result-chart-bottom{
    width:100%;
}

.result-chart-bottom-text{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    font-size:0.8rem;
}

.result-chart-bottom-text-section{
    width:60px;
    text-align:center;
}
