@import "./../global-variables";
@import "./../shared-components";



.result-image{
    text-align:center;
}

.facet-image-text{
    margin-top:20px;
}

.facet-image{
    width:90px;
    height:auto;
}

.facet-image-circle{
    max-width:90px;
    background-color: #20243E;
    border-radius: 120px;
    padding:10px;
    margin-left:auto;
    margin-right:auto;
}


@media screen and (max-width: $mobile-breakpoint) {
    .facet-image{
        width:60px;
    }
    .facet-image-circle{
        max-width:60px;
        padding:5px;
    }
}
