
@import "./../global-variables";
@import "./../shared-components";




.home-body{
    max-width:$max-width;
   margin-left:auto;
   margin-right:auto;
  
}

@media screen and (max-width: $mobile-breakpoint) {
    .home-body{
        margin-left:auto;
        margin-right:auto;
    }
}


.home-section-1{
    padding-top: 80px;
    //background-image: linear-gradient(#242644, #1E273E);
    padding-bottom:40px;


    background-image: linear-gradient(to top, rgba(36,38,68,0.96), rgba(30,39,62,1)), 
    url('/images/background2.png');
  
    background-repeat: no-repeat, repeat; 
    background-size: 100% 100%, 1200px 500px;
   
}



@media screen and (max-width: $mobile-breakpoint) {
    .home-section-1{
        padding: 40px 40px;
        background-size: 100% 100%, 900px 375px;
    }
}


.home-left{
    padding:0px 40px;
}


.home-image{
    width:230px;
    height:auto;
}


.home-icons-image{
    width:200px;
    height:auto;
}

.home-results-image{
    width:auto;
    height:140px;
    margin-top:auto;
    margin-bottom:auto;
}

.home-science-image{
    width:auto;
    height:140px;
    margin-top:auto;
    margin-bottom:auto;
}


@media screen and (max-width: $mobile-breakpoint) {
    .home-image{
        width:156px;
        height:auto;
    }
    .home-small-image{
        width:120px;
        height:auto;

    }
}

.subheading{
    color:$subheading-color;
    font-size:$subheading-font-size;
}

@media screen and (max-width: $mobile-breakpoint) {
    .subheading{
        font-size:$mobile-subheading-font-size;
        text-align:center;
    }
}

.title{
    font-size: $title-font-size;
    font-weight:500;
}

@media screen and (max-width: $mobile-breakpoint) {
    .title{
        font-size:$mobile-title-font-size;
        text-align:center;
    }
}

.home-text{
    
}

.home-section-2{
    background-color: $alt-background-color;
   padding: 80px 0px;
}

@media screen and (max-width: $mobile-breakpoint) {
    .home-section-2{
        padding: 50px 40px;
       
    }
}


.home-section-3{
 
   padding-bottom: 120px;


   background-image: linear-gradient(to top, rgba(36,38,68,0.96), rgba(30,39,62,1)), 
    url('/images/background2.png');
  
    background-repeat: no-repeat, repeat; 
    background-size: 100% 100%, 1200px 500px;
    
}

@media screen and (max-width: $mobile-breakpoint) {
    .home-section-3{
        padding: 0px 40px;
        background-size: 100% 100%, 900px 375px;
    }
}

.home-small-segment{
    margin-top:40px;
}

.home-small-segment-right{
    padding-left:80px;
}

@media screen and (max-width: $mobile-breakpoint) {
    .home-small-segment-right{
        padding-left:0px;
    }

}

.two-icons{
    width:120px;
    height:auto;
}


.image-before{
    display:none;
}

.image-after{
    display:initial;
}

@media screen and (max-width: $mobile-breakpoint) {
    .image-before{
        display:initial;
    }
    .image-after{
        display:none;
    }

}



.home-small-segment-left{
    padding-right:80px;

}

@media screen and (max-width: $mobile-breakpoint) {
    .home-small-segment-left{
      padding-right:0px;
    }

}

.small-title{
    font-weight:600;
    font-size:1.3rem;
    padding-bottom:10px;
}

@media screen and (max-width: $mobile-breakpoint) {
    .small-title{
       font-size: 1.3rem;
       text-align:center;
       padding-top:20px;
    }
}




.buttons-divider{
    padding: 0px 16px;
}

@media screen and (max-width: $mobile-breakpoint) {
    .buttons-divider{
        padding: 8px 0px;
    }
}



  .home-bottom-button{
    margin-top:120px;
    display:flex;
    justify-content:center;
   
   
  }


  @media screen and (max-width: $mobile-breakpoint) {
    .home-bottom-button{
        margin-top:60px;
    }
}