
$small-button-size: 15px;
$medium-button-size: 25px;
$big-button-size: 45px;
$button-outline-size: 7px;

$small-button-size-mobile: 7px;
$medium-button-size-mobile: 13px;
$big-button-size-mobile: 24px;
$button-outline-size-mobile:4px;

$question-font-size:1.2rem;
$question-font-size-mobile:14px;

@import "./../global-variables";
@import "./../shared-components";

.center{
  text-align:center;
}


@media screen and (max-width: $mobile-breakpoint) {
  .test{
    padding: 30px 20px;
  }
}



.test-questions{

  margin-top:60px;

    background-color: $textbox-color;
    max-width:1000px;
    margin-left:auto;
    margin-right:auto;
}

@media screen and (max-width: $mobile-breakpoint) {
  .test-questions{
    margin-top:30px;
      
  }
  

}


.radio-container{


    max-width:1000px;
    margin-left:auto;
    margin-right:auto;
  }





  .horizontal-line{
    color:$white;
    width:70%;
    opacity:0.6;
  }

  .radio-question{

    text-align:center;
    font-size:$question-font-size;
    margin:30px 0px;
  }
  
  @media screen and (max-width: $mobile-breakpoint) {
    .radio-question{
        font-size:$question-font-size-mobile;

    }
  }
  
  .buttons-container{
    display:flex;
    justify-content:space-around;
    margin: 15px 100px;
    border: 0px;
    padding-left:75px;
  }


  @media screen and (max-width: $mobile-breakpoint) {
    .buttons-container{
        margin: 0px 0px;
        padding-left:25px;
        padding-right:25px;
        padding-bottom:45px;
    }
  }
  
  
  .button-box{
      width:100px;
      height:100px;
      display:flex;
      flex-direction:column;
  
      text-align:center;


  
  }


  @media screen and (max-width: $mobile-breakpoint) {
    .button-box{
        width:30px;
        height:30px;
    }
  }
  

  // have to hide these and make a custom button on top 
  .radio-button{

    width:$big-button-size;
    height:$big-button-size;
    position:absolute;
    opacity:0;
    z-index:2; // the invisible button has to go on top so you can click it


    margin-top:-#{$big-button-size/2};
  }


  @media screen and (max-width: $mobile-breakpoint) {
    .radio-button{
      width:$big-button-size-mobile;
      height:$big-button-size-mobile;
      margin-top:-#{$big-button-size-mobile/2};
    }
  }

  .radio-button:hover{
    cursor:pointer;
  }
 
  //probs need to come back to this and straighten it up
  .radio-button-text{
    padding-top: 55px;
    text-align:center;
    font-size: 14px;
    margin-left:-50%;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    .radio-button-text{
      padding-top: 25px;
      font-size: 12px;
    }
  }



  .custom-radio{
    z-index:0;
    position:absolute;
    background-color:none;
    border:$button-outline-size solid $white;
    border-radius: 50%;
  }


  @media screen and (max-width: $mobile-breakpoint) {
    .custom-radio{
      border:$button-outline-size-mobile solid $white;
    }
  }


 

  .custom-radio:hover{
    background-color:gray;
  }

  .medium{
    width:$medium-button-size;
    height:$medium-button-size;
    margin-top:-#{$medium-button-size/2};
  }

  .small{
    width:$small-button-size;
    height:$small-button-size;
    margin-top:-#{$small-button-size/2};
  }

  .big{
    width:$big-button-size;
    height:$big-button-size;
    margin-top:-#{$big-button-size/2};
  }

  

  @media screen and (max-width: $mobile-breakpoint) {
    .small{
      width:$small-button-size-mobile;
      height:$small-button-size-mobile;
      margin-top:-#{$small-button-size-mobile/2};
    }

    .medium{
      width:$medium-button-size-mobile;
      height:$medium-button-size-mobile;
      margin-top:-#{$medium-button-size-mobile/2};
  }

  .big{
    width:$big-button-size-mobile;
    height:$big-button-size-mobile;
    margin-top:-#{$big-button-size-mobile/2};
}
  }

  
  .test-bottom{
    padding: 60px 120px;
  

    margin-left:auto;
    margin-right:auto;
    display:flex;

    justify-content:space-between;
  
  }



  @media screen and (max-width: $mobile-breakpoint) {
    .test-bottom{
      padding:20px 20px;
    }
  }

  .test-bottom-text{
    text-align:center;
    font-weight:600;
  }
  
  .next-button{
    display:block;
  margin-left:auto;
  margin-right:auto;
  }


  .test-top{
    text-align:center;
    padding: 30px 0px;
    font-weight:600;
  }