@import "./../global-variables";
@import "./../shared-components";



.about-body{
    max-width:$max-width;
   margin-left:auto;
   margin-right:auto;

   margin-bottom:160px;
  
}

@media screen and (max-width: $mobile-breakpoint) {
    .about-body{
        margin-left:40px;
        margin-right:40px;
    }
}


.about-text{
    margin-top:60px;
}


.about-section{
    padding-top:120px;
}

@media screen and (max-width: $mobile-breakpoint) {
    .about-section{
        padding-top:60px;
    }
}


.about-subsection{
    padding-top:40px;
}

.about-image{
    width:100%;
    height:auto;
}


.about-image-container{

    max-width:750px;

    margin-top:20px;

    display:flex;
    justify-content:center;

    margin-left:auto;
    margin-right:auto;

}


@media screen and (max-width: $mobile-breakpoint) {
    .about-image-container{

        max-width:500px;

    }
}

.about-image-container-small{

    max-width:550px;

    margin-top:20px;

    display:flex;
    justify-content:center;

    margin-left:auto;
    margin-right:auto;

}




.about-images{

    padding-top:40px;

    justify-content:space-between;

    display:flex;
    flex-direction:row;
}

@media screen and (max-width: $mobile-breakpoint) {
    .about-images{

        max-width:400px;


        flex-direction:column;
        align-items:center;
        justify-content:center;

        margin-left:auto;
        margin-right:auto;
    }
}