
@import "./../global-variables";
@import "./../shared-components";

.results{

    max-width:1000px;
    margin-left:auto;
    margin-right:auto;
    padding-bottom:200px;

    overflow:hidden;
  
}

@media screen and (max-width: $mobile-breakpoint) {
    .results{
        padding: 30px 35px;
    }
}


.results-heading-section{
    
    padding-top: 40px;

    max-width:400px;
    margin-left:auto;
    margin-right:auto;



}

.results-heading-text{
    margin-left:30px;
}

.results-heading-title{
    text-align:center;
    padding-top:36px;
}

.results-heading-subtitle{
    text-align:center;
}

.result-icons{
    display:flex;
    gap:20px;
    justify-content:center;
    padding-top:40px;
    padding-bottom:60px;


}


@media screen and (max-width: $mobile-breakpoint) {
    .result-icons{
        flex-wrap:wrap;
    }
}

.result-icon{
    width:auto;
    height:110px;
    margin-left:auto;
    margin-right:auto;
  
}

@media screen and (max-width: $mobile-breakpoint) {
    .result-icon{
        width:auto;
        height:60px;
        
    }
}


.result{
    display:flex;
    flex-direction: column; 
    gap:20px;

}


@media screen and (max-width: $mobile-breakpoint) {
    .result{
        flex-basis:30%;
    }
}

.result-text{
    text-align:center;

}

.result-letter{
    font-size: 45px;
    text-align:center;
}

.facet{
    padding-bottom:60px;
    display:flex;
    justify-content:center;
    flex-direction:column;
}

.facet-title{
    font-size:$title-font-size;
    padding-top:40px;
    padding-bottom:40px;
    text-align:center;

    display:flex;
    flex-direction:row;
    align-items:center;
}


.facet-title-logo{
    width:80px;
    height:80px;
    padding:40px;
}

@media screen and (max-width: $mobile-breakpoint) {
    .facet-title-logo{
        width:40px;
        height:40px;
        padding:10px;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .facet-title{
        font-size:$mobile-title-font-size;
        text-align:center;
        padding-bottom:10px;
    }
}


.facet-text{
    padding-bottom:26px;
}

.facet-text-right{
    padding: 30px;
    width: 50%;
}


@media screen and (max-width: $mobile-breakpoint) {
    .facet-text-right{
        width:100%;
    }
}

.facet-result-chart{
    
    max-width:550px;
    margin-bottom:40px;

    margin-left:auto;
    margin-right:auto;

}

.facet-text-centered{
    margin-top:auto;
    margin-bottom:auto;
}

.strengths-text{
    padding-left:30px;
}

.facet-logo{
    padding:30px;
    width:110px;
    height:auto;
}

@media screen and (max-width: $mobile-breakpoint) {
    .facet-logo{
        width:auto;
        height:70px;
    }
}

.facet-strengths{
    padding-top:60px;
}

.strengths-title{
    font-weight:500;
    padding-bottom:5px;
}

.strength{
    padding-bottom:5px;
}


@media screen and (max-width: $mobile-breakpoint) {
    .strength{
    }
}

.strength-image{
    width:128px;
    height:auto;
}


@media screen and (max-width: $mobile-breakpoint) {
    .strength-image{
        width:80px;
        height:auto;
    }
}


.reset-test-text{
    margin-top:80px;
    text-align:center;
}

.reset-test-text:hover{
    color: #5364FF;
    transition: 0.3s;
    cursor:pointer;
}

.facet-images{
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    padding-top:40px;
    padding-bottom:40px;
}